"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var flatpickr_1 = require("flatpickr");
var sk_1 = require("flatpickr/dist/l10n/sk");
var averageCycleLenght = 28;
var substractFromCycleLength = 14;
var calculatorBlock = document.querySelector('div#calculator-block');
var calculatorContainer = (calculatorBlock ? calculatorBlock.querySelector('div:has( > #calculator1)') : null);
var chosenCalculatorCache = 0;
if (calculatorBlock) {
    if (calculatorContainer) {
        initCalculator1();
        initCalculator2();
        var events = ['orientationchange', 'resize'];
        events.forEach(function (event) {
            window.addEventListener(event, function () {
                // resetCalculatorsPositions()
                switchToCalculator(null);
                // for(let i = 0; i < 10; i++) {
                setTimeout(function () {
                    fixContainerHeightEtc();
                }, 800);
                // }
            }, false);
        });
        initCalculatorSwitcher();
        setTimeout(function () {
            fixContainerHeightEtc();
        }, 500);
    }
}
function initCalculator1() {
    var _this = this;
    if (!calculatorContainer)
        return;
    var calculator1Form = calculatorContainer.querySelector('#calculator1 form');
    var firstDayOfLastMenCont = calculatorContainer.querySelector('#calculator1 #first_day_of_last_men');
    var cycleLengthCont = calculatorContainer.querySelector('#calculator1 #cycle_length');
    if (!firstDayOfLastMenCont || !cycleLengthCont || !calculator1Form) {
        return;
    }
    // firstDayOfLastMenCont.value = new Date()
    (0, flatpickr_1.default)(firstDayOfLastMenCont, {
        dateFormat: 'd.m.Y',
        allowInput: false,
        locale: sk_1.Slovak,
        defaultDate: new Date()
    });
    cycleLengthCont.value = averageCycleLenght.toString();
    calculator1Form.addEventListener('submit', function (e) { return __awaiter(_this, void 0, void 0, function () {
        var intervalWidth, resultContainer, formData, texts, splitDate, reorderedDate, firstDay, moveBy_1, startDate, endDate;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    switchToCalculator(0);
                    if (!firstDayOfLastMenCont.value || !cycleLengthCont.value) {
                        return [2 /*return*/];
                    }
                    intervalWidth = 1;
                    if (parseInt(cycleLengthCont.value) > 35) {
                        intervalWidth = -1;
                    }
                    else if (parseInt(cycleLengthCont.value) >= 30) {
                        intervalWidth = 2;
                    }
                    resultContainer = document.querySelector('#calculator1 div.result');
                    if (!resultContainer)
                        return [2 /*return*/];
                    if (resultContainer.classList.contains('shown')) {
                        resultContainer.classList.add('resetting');
                    }
                    e.preventDefault();
                    formData = new FormData();
                    formData.append('keys', ['ovul_extra_inf', 'ovul_inf', 'ovul_alt_inf'].join(';'));
                    return [4 /*yield*/, fetch('/customTexts/getTextsByKeys', {
                            method: 'POST',
                            body: formData
                        })];
                case 1: return [4 /*yield*/, (_a.sent()).json()];
                case 2:
                    texts = _a.sent();
                    if (!Object.keys(texts).length)
                        return [2 /*return*/];
                    if (intervalWidth > 0) {
                        splitDate = firstDayOfLastMenCont.value.split('.');
                        reorderedDate = "".concat(splitDate[1], ".").concat(splitDate[0], ".").concat(splitDate[2]);
                        firstDay = new Date(Date.parse(reorderedDate));
                        moveBy_1 = substractFromCycleLength - (averageCycleLenght - parseInt(cycleLengthCont.value));
                        startDate = new Date();
                        startDate.setDate(firstDay.getDate() + moveBy_1 - intervalWidth);
                        endDate = new Date();
                        endDate.setDate(firstDay.getDate() + moveBy_1 + intervalWidth);
                        resultContainer.innerHTML = texts.ovul_inf.replace('{datum1}', startDate.toLocaleDateString('sk-SK')).replace('{datum2}', endDate.toLocaleDateString('sk-SK'));
                    }
                    else {
                        resultContainer.innerHTML = texts.ovul_alt_inf;
                    }
                    resultContainer.innerHTML += '<br><div style="font-size:13px; line-height: 1.2">' +
                        texts.ovul_extra_inf +
                        '</div>';
                    resultContainer.classList.add('shown');
                    resultContainer.classList.remove('resetting');
                    addCloseButton(resultContainer);
                    fixContainerHeightEtc();
                    return [2 /*return*/];
            }
        });
    }); });
}
function initCalculator2() {
    var _this = this;
    if (!calculatorContainer)
        return;
    var calculator2Form = calculatorContainer.querySelector('#calculator2 form');
    var accordingToCont = calculatorContainer.querySelector('#calculator2 #according_to');
    var fromDateCont = calculatorContainer.querySelector('#calculator2 #from_date');
    if (!accordingToCont || !fromDateCont || !calculator2Form) {
        return;
    }
    // fromDateCont.valueAsDate = new Date()
    (0, flatpickr_1.default)(fromDateCont, {
        dateFormat: 'd.m.Y',
        allowInput: false,
        locale: sk_1.Slovak,
        defaultDate: new Date()
    });
    calculator2Form.addEventListener('submit', function (e) { return __awaiter(_this, void 0, void 0, function () {
        var fromDate, splitDate, reorderedDate, resultContainer, formData, text, matches, newText;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    switchToCalculator(1);
                    if (!accordingToCont.value || !fromDateCont.value) {
                        return [2 /*return*/];
                    }
                    splitDate = fromDateCont.value.split('.');
                    reorderedDate = "".concat(splitDate[1], ".").concat(splitDate[0], ".").concat(splitDate[2]);
                    if (accordingToCont.value === '1') {
                        fromDate = new Date(Date.parse(reorderedDate));
                    }
                    else {
                        fromDate = new Date(Date.parse(reorderedDate) - (38 * 7 * 24 * 60 * 60 * 1000));
                    }
                    resultContainer = document.querySelector('#calculator2 div.result');
                    if (!resultContainer)
                        return [2 /*return*/];
                    if (resultContainer.classList.contains('shown')) {
                        resultContainer.classList.add('resetting');
                    }
                    e.preventDefault();
                    formData = new FormData();
                    formData.append('keys', 'preg_calc');
                    return [4 /*yield*/, fetch('/customTexts/getTextsByKeys', {
                            method: 'POST',
                            body: formData
                        })];
                case 1: return [4 /*yield*/, (_b.sent()).json()];
                case 2:
                    text = (_b.sent()).preg_calc;
                    matches = (_a = text.match(/\{tyzden\d+\}/g)) === null || _a === void 0 ? void 0 : _a.map(function (m) { var _a; return (_a = m.match(/\d+/)) === null || _a === void 0 ? void 0 : _a.join(''); });
                    newText = text;
                    matches === null || matches === void 0 ? void 0 : matches.forEach(function (m) {
                        newText = newText.replace("{tyzden".concat(m, "}"), genDate(fromDate, parseInt(m !== null && m !== void 0 ? m : '')));
                    });
                    resultContainer.innerHTML = '<div>' +
                        newText +
                        '</div>';
                    resultContainer.classList.add('shown');
                    resultContainer.classList.remove('resetting');
                    addCloseButton(resultContainer);
                    fixContainerHeightEtc();
                    return [2 /*return*/];
            }
        });
    }); });
}
function fixContainerHeightEtc() {
    if (!calculatorContainer)
        return;
    var newHeight = 0;
    if (window.innerWidth > 1201) {
        newHeight = Math.max.apply(Math, Array.from(calculatorContainer.children).filter(function (el) { return !el.classList.contains('fake-border'); }).map(function (el) { return el.offsetHeight; }));
    }
    else {
        var calculators = Array.from(calculatorContainer.querySelectorAll('[id^="calculator"]'));
        newHeight = calculators[chosenCalculatorCache].offsetHeight;
    }
    calculatorContainer.style.height = newHeight + 'px';
}
function initCalculatorSwitcher() {
    if (!calculatorBlock)
        return;
    var switcherButtons = Array.from(calculatorBlock.querySelectorAll('div.card-header-actions a'));
    var _loop_1 = function (i) {
        switcherButtons[i].addEventListener('click', function () {
            switchToCalculator(i);
        });
    };
    for (var i = 0; i < switcherButtons.length; i++) {
        _loop_1(i);
    }
}
function switchToCalculator(switchTo) {
    if (!calculatorContainer)
        return;
    if (switchTo === null) {
        switchTo = chosenCalculatorCache;
    }
    var winWidth = window.innerWidth;
    var calculators = Array.from(calculatorContainer.querySelectorAll('[id^="calculator"]'));
    var percentVal = 100;
    var extraPer = 10;
    var plusOne = 0;
    var switchBack = 0;
    if (winWidth > 1201) {
        percentVal = 50;
        extraPer = 0;
        plusOne = 1;
        if (switchTo + 1 === calculators.length) {
            switchTo--;
            switchBack = 1;
        }
    }
    var moveBy = switchTo * percentVal + 10;
    if (calculators.length > switchTo + plusOne) {
        for (var i = 0; i < calculators.length; i++) {
            if (i < switchTo) {
                calculators[i].style.left = (-moveBy).toString() + '%';
            }
            else if (i > switchTo) {
                calculators[i].style.left = (moveBy).toString() + '%';
            }
            else {
                calculators[i].style.left = '0%';
                moveBy = extraPer;
            }
            moveBy += percentVal;
        }
    }
    chosenCalculatorCache = switchTo + switchBack;
    // setTimeout(() => {
    fixContainerHeightEtc();
    // }, 500)
}
function genDate(fromDate, plusWeeks) {
    var newDate = new Date();
    newDate.setTime(fromDate.getTime() + plusWeeks * 7 * 24 * 60 * 60 * 1000);
    return newDate.toLocaleDateString('sk-SK');
}
function addCloseButton(resultContainer) {
    resultContainer.style.position = 'relative';
    var closeButton = document.createElement('div');
    closeButton.innerHTML = '&#10006';
    closeButton.classList.add('cl-button');
    closeButton.addEventListener('click', function () {
        resultContainer.classList.add('resetting');
        setTimeout(function () {
            resultContainer.innerHTML = '';
            setTimeout(function () {
                resultContainer.classList.remove('shown');
                fixContainerHeightEtc();
            }, 500);
        }, 2100);
    });
    resultContainer.appendChild(closeButton);
}
